<template>
  <div class="classManage">
    <div class="flex-box">
      <div>
        <el-button type="success"
                   icon="el-icon-plus"
                   @click="clickAddClassroom">添加教室</el-button>
        <el-button type="danger"
                   @click="batchDelete"
                   icon="el-icon-delete">批量删除</el-button>
      </div>
      <div>
        <el-input placeholder="请输入关键词"
                  v-model="queryName"
                  class="input-box"></el-input>
        <el-button type="primary"
                   @click="loadClassroom">查 询</el-button>
      </div>
    </div>
    <el-table :data="classroomTable"
              @selection-change="handleSelectionChange">
      <el-table-column type="selection"
                       align="center"
                       width="55px"></el-table-column>
      <el-table-column label="序号"
                       type="index"
                       align="center"
                       width="50px"></el-table-column>
      <el-table-column label="教学楼名称"
                       prop="floor_name"
                       align="center"></el-table-column>
      <el-table-column label="教学楼编号"
                       prop="floor_number"
                       align="center"></el-table-column>
      <el-table-column label="教室楼层数"
                       prop="room_floor"
                       align="center"></el-table-column>
      <el-table-column label="教室名称"
                       prop="room_name"
                       align="center"></el-table-column>
      <el-table-column label="教室编号"
                       prop="room_number"
                       align="center"></el-table-column>
      <el-table-column label="提交时间"
                       prop="opertime"
                       align="center"></el-table-column>
      <el-table-column label="提交人"
                       prop="opername"
                       align="center"></el-table-column>
      <el-table-column label="操作"
                       width="180px"
                       align="center">
        <template slot-scope="scope">
          <el-button type="primary"
                     @click="editClassForm(scope.row)">编 辑</el-button>
          <el-button type="danger"
                     @click="deleteClassForm(scope.row.id)">删 除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="classFormVisible"
               :title="classForm.id?'编辑':'添加'"
               width="600px">
      <el-form :model="classForm"
               label-width="100px"
               :rules="classFormRule"
               ref="classForm">
        <!-- <el-form-item label="选择班级"
                      prop="cls">
          <el-cascader v-model="classForm.cls"
                       :options="gradeList"
                       placeholder="请选择班级"></el-cascader>
        </el-form-item> -->
        <el-form-item label="教室编号"
                      prop="clsNumber">
          <el-input v-model="classForm.clsNumber"></el-input>
        </el-form-item>
        <el-form-item label="教室名称"
                      prop="clsName">
          <el-input v-model="classForm.clsName"></el-input>
        </el-form-item>
        <el-form-item label="教学楼编号"
                      prop="academicBuildingNumber">
          <el-input v-model="classForm.academicBuildingNumber"></el-input>
        </el-form-item>
        <el-form-item label="教学楼名称"
                      prop="academicBuildingName">
          <el-input v-model="classForm.academicBuildingName"></el-input>
        </el-form-item>
        <el-form-item label="楼层数"
                      prop="floorNumber">
          <el-input v-model="classForm.floorNumber"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="classFormVisible=false">取 消</el-button>
        <el-button @click="addClassFormConfirm"
                   type="primary">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "classManage",
  data() {
    return {
      classroomTable: [],
      classList: [],
      classFormVisible: false,
      classForm: {
        cls: "",
        clsNumber: "",
        clsName: "",
        academicBuildingNumber: "",
        academicBuildingName: "",
        floorNumber: "",
      },
      classFormRule: {
        // cls: [{ required: true, message: "请选择班级", trigger: "blur" }],
        // clsNumber: [
        //   { required: true, message: "请输入教室编号", trigger: "blur" },
        // ],
        // clsName: [
        //   { required: true, message: "请输入教室名称", trigger: "blur" },
        // ],
        // academicBuildingNumber: [
        //   { required: true, message: "请输入教学楼编号", trigger: "blur" },
        // ],
        // academicBuildingName: [
        //   { required: true, message: "请输入教学楼名称", trigger: "blur" },
        // ],
        // floorNumber: [
        //   { required: true, message: "请输入楼层树", trigger: "blur" },
        // ],
      },
      managementModule: JSON.parse(sessionStorage.getItem("userInfo")).nav1,
      roleType: false,
      gradeList: [],
      multipleSelection: [],
      queryName: "",
    };
  },
  created() {
    //判断是否为管理员，103为教室管理页面id
    for (let i = 0; i < this.managementModule.length; i++) {
      if (this.managementModule[i].id === 103) {
        this.roleType = true;
        break;
      }
    }
    this.loadClassroom();
    // this.loadClass();
  },
  methods: {
    loadClassroom() {
      let data = { name: this.queryName };
      this.$post("/base/queryClassroom.do", data)
        .then((res) => {
          this.classroomTable = res.data;
        })
        .catch((err) => {
          this.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    loadClass() {
      this.$post("/media/queryClassesByYear.do", {
        type: this.roleType ? 1 : 2,
      })
        .then((res) => {
          let gradeList = [];
          res.data.forEach((grade) => {
            let children = [];
            grade.classlist.forEach((cls) => {
              children.push({ value: cls.id, label: cls.cname });
            });
            gradeList.push({ value: grade.id, label: grade.gname, children });
          });
          this.gradeList = gradeList;
        })
        .catch((err) => {
          this.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    clickAddClassroom() {
      this.classFormVisible = true;
      this.$nextTick(() => {
        this.$refs["classForm"].resetFields();
        this.classForm.id = "";
      });
    },
    addClassFormConfirm() {
      this.$refs["classForm"].validate((valid) => {
        if (valid) {
          let apiUrl = "/base/insertClassroom.do";
          let data = {
            floor_name: this.classForm.academicBuildingName,
            floor_number: this.classForm.academicBuildingNumber,
            room_floor: this.classForm.floorNumber,
            room_name: this.classForm.clsName,
            room_number: this.classForm.clsNumber,
          };
          if (this.classForm.id) {
            apiUrl = "/base/updateClassroom.do";
            data.id = this.classForm.id;
          }
          this.$confirm("是否确认该操作？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$post(apiUrl, data)
                .then((res) => {
                  this.classFormVisible = false;
                  this.loadClassroom();
                  this.$message({ type: "success", message: res.message });
                })
                .catch((err) => {
                  this.$message({ type: "warning", message: err.message });
                });
            })
            .catch(() => {
              this.$message({ type: "warning", message: "已取消确认" });
            });
        }
      });
    },
    editClassForm(row) {
      this.classFormVisible = true;
      this.$nextTick(() => {
        this.classForm.id = row.id;
        this.classForm.clsNumber = row.room_number;
        this.classForm.clsName = row.room_name;
        this.classForm.academicBuildingNumber = row.floor_number;
        this.classForm.academicBuildingName = row.floor_name;
        this.classForm.floorNumber = row.room_floor;
      });
    },
    deleteClassForm(id) {
      let dataId = id.toString();
      console.log(typeof dataId);
      this.$confirm("是否确认删除该数据？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/base/deleteClassroom.do", { ids: dataId })
            .then((res) => {
              this.loadClassroom();
              this.$message({ type: "success", message: res.message });
            })
            .catch((err) => {
              this.$message({ type: "warning", message: err.message });
            });
        })
        .catch(() => {
          this.$message({ type: "warning", message: "已取消删除" });
        });
    },
    handleSelectionChange(event) {
      this.multipleSelection = [];
      event.forEach((row) => {
        this.multipleSelection.push(row.id);
      });
    },
    batchDelete() {
      let idList = this.multipleSelection.toString();
      this.deleteClassForm(idList);
    },
  },
};
</script>

<style scoped lang="scss">
.flex-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.input-box {
  width: 200px;
  margin-right: 5px;
}
</style>
